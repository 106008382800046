<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <h1 class="text-center">Manage Listings</h1>
      <div class="card-deck mb-2 text-center mt-5">
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Horse Listings</h4>
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-lg btn-block btn-primary" @click="$router.push('my-listings')">Manage</button>
          </div>
        </div>
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Services Listings</h4>
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-lg btn-block btn-primary" @click="$router.push('my-services')">Manage</button>
          </div>
        </div>
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Advertisements Listings</h4>
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-lg btn-block btn-primary" @click="$router.push('my-ads')">Manage</button>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';

export default {
  components: {
    BaseLayout,
    SubHeader
  }
};
</script>
